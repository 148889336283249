import React from "react";
import "./App.scss";
import { ReactComponent as Cloud } from "./assets/images/cloud.svg";

const clouds = [1, 2, 3, 4];

function App() {
    const [menuActive, setMenuActive] = React.useState(false);
    return (
        <div className="App">
            <header className="App-header">
                <span className="App-logo_company-name">Webicom</span>
                <div className="App-header_menu">
                    <button
                        className={`nav ${menuActive ? "open" : "closed"}`}
                        onClick={() => {
                            setMenuActive(menuActive ? false : true);
                        }}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className={`App-menu ${menuActive ? "active" : ""}`}>
                    <nav>
                        <ul className="App-menu_nav">
                            <li>
                                <a href="mailto:info@webicom.nl">Contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <main>
                <div className="App-content">
                    <div className="App-birds">
                        <div className="bird-container bird-container--one">
                            <div className="bird bird--one"></div>
                        </div>

                        <div className="bird-container bird-container--two">
                            <div className="bird bird--two"></div>
                        </div>

                        <div className="bird-container bird-container--three">
                            <div className="bird bird--three"></div>
                        </div>

                        <div className="bird-container bird-container--four">
                            <div className="bird bird--four"></div>
                        </div>
                    </div>
                    <div className="App-clouds">
                        {clouds.map((id) => (
                            <div key={id} className={`animate cloud-${id}`}>
                                <div className={`cloud cloud-${id}`}>
                                    <Cloud />
                                </div>
                            </div>
                        ))}
                        <div className={`rocket-container`}>
                            <div className={`rocket`}></div>
                        </div>
                    </div>
                    <span className="App-logo_company-slogan">software development</span>
                    <div className="App-content_logo">
                        <svg className="App-logo_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.264 279.48">
                            <g id="icon" transform="translate(-560.118 -127.368)">
                                <path
                                    id="Subtraction_7"
                                    data-name="Subtraction 7"
                                    d="M100.5,201a101.241,101.241,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(600.75 369.75) rotate(-90)"
                                    fill="#f3c1c4"
                                />
                                <path
                                    id="Subtraction_5"
                                    data-name="Subtraction 5"
                                    d="M100.5,201a101.242,101.242,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(801.5 370) rotate(180)"
                                    fill="#f3c1c4"
                                />
                                <path
                                    id="Subtraction_4"
                                    data-name="Subtraction 4"
                                    d="M100.5,201a101.242,101.242,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(843.382 270.496) rotate(135)"
                                    fill="#fbeaeb"
                                />
                                <path
                                    id="Subtraction_3"
                                    data-name="Subtraction 3"
                                    d="M100.5,201a101.242,101.242,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(801.75 169.25) rotate(90)"
                                    fill="#f3c1c4"
                                />
                                <path
                                    id="Subtraction_2"
                                    data-name="Subtraction 2"
                                    d="M100.5,201a101.242,101.242,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(702.247 127.368) rotate(45)"
                                    fill="#fbeaeb"
                                />
                                <path
                                    id="Subtraction_8"
                                    data-name="Subtraction 8"
                                    d="M100.5,202a100.758,100.758,0,0,1-20.254-2.052,99.687,99.687,0,0,1-35.936-15.2A101.173,101.173,0,0,1,61.381,7.937,99.631,99.631,0,0,1,80.246,2.052,100.759,100.759,0,0,1,100.5,0c3.335,0,6.7.166,10,.494a100.045,100.045,0,0,0-64.3,32.5,101.229,101.229,0,0,0,0,136.016,100.047,100.047,0,0,0,64.3,32.5C107.193,201.834,103.828,202,100.5,202Z"
                                    transform="translate(562.657 305.848) rotate(-60)"
                                    fill="#fbeaeb"
                                />
                                <path
                                    id="Subtraction_1"
                                    data-name="Subtraction 1"
                                    d="M100.5,201a101.242,101.242,0,0,1-20.254-2.042A99.95,99.95,0,0,1,44.31,183.836,100.794,100.794,0,0,1,7.9,139.619a99.988,99.988,0,0,1-5.856-18.865,101.478,101.478,0,0,1,0-40.508A99.948,99.948,0,0,1,17.164,44.31,100.794,100.794,0,0,1,61.381,7.9,99.993,99.993,0,0,1,80.246,2.042,101.242,101.242,0,0,1,100.5,0c3.335,0,6.7.165,10,.491a100.508,100.508,0,0,0,0,200.017C107.193,200.835,103.828,201,100.5,201Z"
                                    transform="translate(599 169)"
                                    fill="#f3c1c4"
                                />
                                <path
                                    id="Subtraction_9"
                                    data-name="Subtraction 9"
                                    d="M42.292,84a42.89,42.89,0,0,1-8.523-.853,42.236,42.236,0,0,1-30.445-24.8A41.575,41.575,0,0,1,.859,50.464a42.122,42.122,0,0,1,0-16.929A41.616,41.616,0,0,1,7.223,18.517,42.3,42.3,0,0,1,33.769.853,42.89,42.89,0,0,1,42.292,0c1.4,0,2.819.069,4.208.205a42.331,42.331,0,0,0-31.672,19.55,41.72,41.72,0,0,0,0,44.49A42.331,42.331,0,0,0,46.5,83.794C45.108,83.931,43.692,84,42.292,84Z"
                                    transform="matrix(0.695, 0.719, -0.719, 0.695, 643.427, 232.716)"
                                    fill="#fbeaeb"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default App;
